import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div id="about" className="home-container">
      <Helmet>
        <title>dakchi li fhamt</title>
        <meta property="og:title" content="dakchi li fhamt" />
      </Helmet>
      <header data-thq="thq-navbar" className="home-navbar-interactive">
        <Link to="/" className="home-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
            className="home-image"
          />
        </Link>
        <div data-thq="thq-navbar-nav" className="home-desktop-menu">
          <nav className="home-links">
            <a href="#about us" className="home-link">
              About
            </a>
            <a href="#cour" className="home-link1">
              courses
            </a>
            <Link to="/donate" className="home-navlink01">
              Donate
            </Link>
          </nav>
          <div className="home-buttons">
            <Link to="/apply" className="home-register button">
              Apply
            </Link>
            <div
              data-thq="thq-dropdown"
              className="home-thq-dropdown list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="home-dropdown-toggle"
              >
                <span className="home-text">Language</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="home-dropdown-arrow"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul data-thq="thq-dropdown-list" className="home-dropdown-list">
                <li data-thq="thq-dropdown" className="home-dropdown list-item">
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="home-dropdown-toggle1"
                    >
                      <span className="home-text01">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="home-image1"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown1 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="home-dropdown-toggle2"
                    >
                      <span className="home-text02">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="home-image2"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown2 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown3 list-item"
                ></li>
              </ul>
            </div>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon02">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="home-mobile-menu">
          <div className="home-nav">
            <div className="home-top">
              <img
                alt="image"
                src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
                className="home-logo"
              />
              <div data-thq="thq-close-menu" className="home-close-menu">
                <svg viewBox="0 0 1024 1024" className="home-icon04">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="home-links1">
              <a href="#about us" className="home-link2">
                About
              </a>
              <a href="#cour" className="home-link3">
                Courses
              </a>
              <Link to="/donate" className="home-navlink04">
                Donate
              </Link>
            </nav>
            <div className="home-buttons1">
              <Link to="/apply" className="home-register1 button">
                <span>Apply</span>
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="home-thq-dropdown1 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="home-dropdown-toggle3"
              >
                <span className="home-text04">Language</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="home-dropdown-arrow1"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul data-thq="thq-dropdown-list" className="home-dropdown-list1">
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown4 list-item"
                >
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="home-dropdown-toggle4"
                    >
                      <span className="home-text05">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="home-image3"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown5 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="home-dropdown-toggle5"
                    >
                      <span className="home-text06">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="home-image4"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown6 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="home-dropdown7 list-item"
                ></li>
              </ul>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon10">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon12">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="home-container01">
        <div className="home-container02">
          <span className="home-text07">Together we all win</span>
          <img
            alt="image"
            src="/10717948_2091-300h.jpg"
            className="home-image5"
          />
          <img
            alt="image"
            src="/hero-banner-500h.png"
            className="home-image6"
          />
          <span className="home-text08">
            DAKCHI LI FHAMT is a platform where students share their
            understanding of lessons with each other.
          </span>
          <a href="#cour" className="home-link6 button">
            <span className="home-text09">COURSES</span>
            <svg viewBox="0 0 1024 1024" className="home-icon14">
              <path d="M512 909.312l-303.829-303.829c-49.92-49.92-49.92-131.115 0-180.992 46.677-46.677 127.061-48.555 175.829-4.907v-206.251c0-70.571 57.429-128 128-128s128 57.429 128 128v206.251c48.768-43.648 129.152-41.771 175.829 4.907 49.92 49.877 49.92 131.072 0 180.992l-303.829 303.829zM298.667 472.32c-11.435 0-22.101 4.437-30.165 12.501-16.683 16.64-16.683 43.648 0 60.331l243.499 243.499 243.499-243.499c16.683-16.683 16.683-43.691 0-60.331-16.171-16.171-44.16-16.171-60.331 0l-140.501 140.501v-411.989c0-23.509-19.115-42.667-42.667-42.667s-42.667 19.157-42.667 42.667v411.989l-140.501-140.501c-8.064-8.064-18.731-12.501-30.165-12.501z"></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="home-container03">
        <div className="home-container04">
          <span className="home-text10">
            <span className="home-text11">This is a project for:</span>
            <br></br>
          </span>
          <img
            alt="image"
            src="https://images.ctfassets.net/0t98t0c4kteu/4eylnFldrXWnQJcNG6M6Xm/a5a1e80f720a0a017a5954dafe240828/Rise_PrimaryAlt-01.svg?w=320"
            className="home-image7"
          />
        </div>
      </div>
      <span id="about us" className="home-text13">
        OUR MISSION:
      </span>
      <div className="home-container05">
        <div className="home-container06">
          <img
            alt="image"
            src="/brainstorm-meeting%20(1)-500h.jpg"
            className="home-image8"
          />
          <span className="home-text14">
            <span>
              &quot;At DAKCHI LI FHAMT, we aim to create an inclusive learning
              space. We offer a no-cost platform for students to exchange
              insights, fostering collaborative learning. Our goal is to build a
              community where every learner can contribute, learn, and thrive
              collectively. We are committed to supporting intellectual growth,
              diverse perspectives, and a culture of shared learning for
              all.&quot;
            </span>
            <br></br>
          </span>
        </div>
        <span className="home-text17">-Salah Edine Mamouni</span>
      </div>
      <div id="courses" className="home-container07">
        <span className="home-text18">WATCH OUR MOST</span>
        <span id="cour" className="home-text19">
          POPULAR COURSES
        </span>
      </div>
      <div className="home-container08">
        <div className="home-container09">
          <Link to="/profile-salah-mamouni" className="home-navlink07">
            Salah Edine Mamouni
          </Link>
        </div>
        <div className="home-container10">
          <span className="home-text20">2BAC ENGLISH</span>
          <ul className="home-ul list">
            <li className="list-item">
              <span className="home-text21">•Youth</span>
            </li>
            <li className="list-item">
              <span className="home-text22">•Humour</span>
            </li>
            <li className="list-item">
              <span className="home-text23">•Education</span>
            </li>
          </ul>
          <Link to="/english-course" className="home-navlink08 navtitle button">
            START
          </Link>
        </div>
      </div>
      <div className="home-container11">
        <div className="home-container12">
          <Link to="/profile-yousra-ouad" className="home-navlink09">
            Yousra Oual
          </Link>
        </div>
        <div className="home-container13">
          <span className="home-text24">WEB DEVELOPEMENT</span>
          <ul className="home-ul1 list">
            <li className="list-item">
              <span className="home-text25">•Html</span>
            </li>
            <li className="list-item">
              <span className="home-text26">•CSS</span>
            </li>
            <li className="list-item">
              <span className="home-text27">•JavaScript</span>
            </li>
          </ul>
          <Link to="/codey" className="home-navlink10 navtitle button">
            START
          </Link>
        </div>
      </div>
      <div className="home-container14">
        <div className="home-container15">
          <Link to="/profile-rawane" className="home-navlink11">
            Rawane Loutfi
          </Link>
        </div>
        <div className="home-container16">
          <span className="home-text28">Understanding the layers of ADHD</span>
          <Link to="/mental" className="home-navlink12 navtitle button">
            START
          </Link>
        </div>
      </div>
      <div className="home-steps">
        <h1 className="home-text29">Our Process</h1>
        <span className="home-text30">
          <span>for people to post they must go through these steps</span>
          <br></br>
        </span>
        <div className="home-container17">
          <div className="home-step">
            <div className="home-container18">
              <div className="home-line"></div>
              <div className="home-container19">
                <svg viewBox="0 0 1024 1024" className="home-icon16">
                  <path d="M512 820q68 0 143-40t113-98q-2-56-90-94t-166-38-166 37-90 95q38 58 113 98t143 40zM512 214q-52 0-90 38t-38 90 38 90 90 38 90-38 38-90-38-90-90-38zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
                </svg>
              </div>
              <div className="home-line1"></div>
            </div>
            <div className="home-container20">
              <h1 className="home-text33">Apply</h1>
              <span className="home-text34">
                <span>
                  Tell us why you are you want to be a student teacher
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-step1">
            <div className="home-container21">
              <div className="home-line2"></div>
              <div className="home-container22">
                <svg viewBox="0 0 1024 1024" className="home-icon18">
                  <path d="M512 128l470 256v342h-86v-296l-384 210-470-256zM214 562l298 164 298-164v172l-298 162-298-162v-172z"></path>
                </svg>
              </div>
              <div className="home-line3"></div>
            </div>
            <div className="home-container23">
              <h1 className="home-text37">Learn</h1>
              <span className="home-text38">
                <span>
                  acces our course on how to create content in dakchilifhamt
                </span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-step2">
            <div className="home-container24">
              <div className="home-line4"></div>
              <div className="home-container25">
                <svg viewBox="0 0 1024 1024" className="home-icon20">
                  <path d="M884 300l-78 78-160-160 78-78q12-12 30-12t30 12l100 100q12 12 12 30t-12 30zM128 736l472-472 160 160-472 472h-160v-160z"></path>
                </svg>
              </div>
              <div className="home-line5"></div>
            </div>
            <div className="home-container26">
              <h1 className="home-text41">Create</h1>
              <span className="home-text42">
                <span>use what you learned to create your first video</span>
                <br></br>
              </span>
            </div>
          </div>
          <div className="home-step3">
            <div className="home-container27">
              <div className="home-line6"></div>
              <div className="home-container28">
                <svg viewBox="0 0 1024 1024" className="home-icon22">
                  <path d="M480 64c-265.096 0-480 214.904-480 480 0 265.098 214.904 480 480 480 265.098 0 480-214.902 480-480 0-265.096-214.902-480-480-480zM480 928c-212.078 0-384-171.922-384-384s171.922-384 384-384c212.078 0 384 171.922 384 384s-171.922 384-384 384zM512 512v-128h128v-64h-128v-64h-64v64h-128v256h128v128h-128v64h128v64h64v-64h128.002l-0.002-256h-128zM448 512h-64v-128h64v128zM576.002 704h-64.002v-128h64.002v128z"></path>
                </svg>
              </div>
              <div className="home-line7"></div>
            </div>
            <div className="home-container29">
              <h1 className="home-text45">get compensated</h1>
              <span className="home-text46">
                <span>add video ads and get compensated</span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  )
}

export default Home