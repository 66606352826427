import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <Link to="/" className="footer-navlink">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="footer-image"
        />
      </Link>
      <span className="footer-text">{props.text}</span>
      <div className="footer-icon-group">
        <a
          href="https://www.youtube.com/@dakchilifhamt"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-link"
        >
          <svg viewBox="0 0 1024 1024" className="footer-icon">
            <path
              d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
              className=""
            ></path>
          </svg>
        </a>
        <a
          href="http://tiktok.com/@dakchi.li.fhamt"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-link1"
        >
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="footer-image1"
          />
        </a>
        <a
          href="http://instagram.com/dakchi.li.fhamt/"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-link2"
        >
          <svg viewBox="0 0 877.7142857142857 1024" className="footer-icon2">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              className=""
            ></path>
          </svg>
        </a>
        <a
          href="http://facebook.com/dkchi.li.fhamt/"
          target="_blank"
          rel="noreferrer noopener"
          className="footer-link3"
        >
          <svg viewBox="0 0 602.2582857142856 1024" className="footer-icon4">
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              className=""
            ></path>
          </svg>
        </a>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  rootClassName: '',
  image_alt: 'logo',
  image_src1: '/t%C3%A3%C2%A9l%C3%A3%C2%A9chargement%20(1)-200h.png',
  image_alt1: 'image',
  text: '© 2024 DAKCHI LI FHAMT, All Rights Reserved.',
  image_src: '/untitled_designfgfhdfdgh-removebg-preview-200h.png',
}

Footer.propTypes = {
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
}

export default Footer
