import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './bac-english-unit-3.css'

const BacEnglishUnit3 = (props) => {
  return (
    <div className="bac-english-unit3-container">
      <Helmet>
        <title>bac-english-unit-3 - dakchi li fhamt</title>
        <meta
          property="og:title"
          content="bac-english-unit-3 - dakchi li fhamt"
        />
      </Helmet>
      <header
        data-thq="thq-navbar"
        className="bac-english-unit3-navbar-interactive"
      >
        <Link to="/" className="bac-english-unit3-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-1500h.png"
            className="bac-english-unit3-image"
          />
        </Link>
        <div
          data-thq="thq-navbar-nav"
          className="bac-english-unit3-desktop-menu"
        >
          <nav className="bac-english-unit3-links">
            <Link to="/" className="bac-english-unit3-navlink1">
              Home
            </Link>
            <Link to="/donate" className="bac-english-unit3-navlink2">
              Donate
            </Link>
          </nav>
          <div className="bac-english-unit3-buttons">
            <Link to="/apply" className="bac-english-unit3-register button">
              Apply
            </Link>
          </div>
        </div>
        <div
          data-thq="thq-burger-menu"
          className="bac-english-unit3-burger-menu"
        >
          <svg viewBox="0 0 1024 1024" className="bac-english-unit3-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="bac-english-unit3-mobile-menu"
        >
          <div className="bac-english-unit3-nav">
            <div className="bac-english-unit3-top">
              <Link to="/" className="bac-english-unit3-navlink3">
                <img
                  alt="image"
                  src="/untitled_designfgfhdfdgh-removebg-preview-1500h.png"
                  className="bac-english-unit3-logo"
                />
              </Link>
              <div
                data-thq="thq-close-menu"
                className="bac-english-unit3-close-menu"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  className="bac-english-unit3-icon02"
                >
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="bac-english-unit3-links1">
              <Link to="/" className="bac-english-unit3-navlink4">
                Home
              </Link>
              <Link to="/donate" className="bac-english-unit3-navlink5">
                Donate
              </Link>
            </nav>
            <div className="bac-english-unit3-buttons1">
              <Link to="/apply" className="bac-english-unit3-register1 button">
                <span>Apply</span>
                <svg
                  viewBox="0 0 1024 1024"
                  className="bac-english-unit3-icon04"
                >
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="bac-english-unit3-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="bac-english-unit3-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="bac-english-unit3-container01">
        <span className="bac-english-unit3-text1">2BAC ENGLISH: UNIT 3</span>
      </div>
      <div className="bac-english-unit3-container02">
        <img
          alt="image"
          src="https://i.ibb.co/nrrFh5g/Photo-Room-20231207-192027-2.png"
          className="bac-english-unit3-image1"
        />
        <div className="bac-english-unit3-container03">
          <div className="bac-english-unit3-container04">
            <div className="bac-english-unit3-container05">
              <span className="bac-english-unit3-text2">Past Perfect</span>
            </div>
          </div>
          <Link
            to="/unit3-pastperfect-salah-mamouni"
            className="bac-english-unit3-navlink6 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <div className="bac-english-unit3-container06">
        <img
          alt="image"
          src="https://i.ibb.co/nrrFh5g/Photo-Room-20231207-192027-2.png"
          className="bac-english-unit3-image2"
        />
        <div className="bac-english-unit3-container07">
          <div className="bac-english-unit3-container08">
            <div className="bac-english-unit3-container09">
              <span className="bac-english-unit3-text3">
                Expressing purpose
              </span>
            </div>
          </div>
          <Link
            to="/unit3-expressing-purpose-salah-mamouni"
            className="bac-english-unit3-navlink7 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name15"></Footer>
    </div>
  )
}

export default BacEnglishUnit3
