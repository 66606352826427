import React from 'react'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './profile-rawane.css'

const ProfileRawane = (props) => {
  return (
    <div className="profile-rawane-container">
      <Helmet>
        <title>profile-rawane - dakchi li fhamt</title>
        <meta property="og:title" content="profile-rawane - dakchi li fhamt" />
      </Helmet>
      <div className="profile-rawane-container1">
        <div className="profile-rawane-container2">
          <img
            alt="image"
            src="/4e42442f443359cf0115c075a788f546-400w.jpg"
            className="profile-rawane-image"
          />
          <h1 className="profile-rawane-text">Rawane loutfi</h1>
          <a
            href="https://www.instagram.com/ange.myrtille"
            target="_blank"
            rel="noreferrer noopener"
            className="profile-rawane-link"
          >
            <svg viewBox="0 0 1024 1024" className="profile-rawane-icon">
              <path d="M512 92.2c136.8 0 153 0.6 206.8 3 50 2.2 77 10.6 95 17.6 23.8 9.2 41 20.4 58.8 38.2 18 18 29 35 38.4 58.8 7 18 15.4 45.2 17.6 95 2.4 54 3 70.2 3 206.8s-0.6 153-3 206.8c-2.2 50-10.6 77-17.6 95-9.2 23.8-20.4 41-38.2 58.8-18 18-35 29-58.8 38.4-18 7-45.2 15.4-95 17.6-54 2.4-70.2 3-206.8 3s-153-0.6-206.8-3c-50-2.2-77-10.6-95-17.6-23.8-9.2-41-20.4-58.8-38.2-18-18-29-35-38.4-58.8-7-18-15.4-45.2-17.6-95-2.4-54-3-70.2-3-206.8s0.6-153 3-206.8c2.2-50 10.6-77 17.6-95 9.2-23.8 20.4-41 38.2-58.8 18-18 35-29 58.8-38.4 18-7 45.2-15.4 95-17.6 53.8-2.4 70-3 206.8-3zM512 0c-139 0-156.4 0.6-211 3-54.4 2.4-91.8 11.2-124.2 23.8-33.8 13.2-62.4 30.6-90.8 59.2-28.6 28.4-46 57-59.2 90.6-12.6 32.6-21.4 69.8-23.8 124.2-2.4 54.8-3 72.2-3 211.2s0.6 156.4 3 211c2.4 54.4 11.2 91.8 23.8 124.2 13.2 33.8 30.6 62.4 59.2 90.8 28.4 28.4 57 46 90.6 59 32.6 12.6 69.8 21.4 124.2 23.8 54.6 2.4 72 3 211 3s156.4-0.6 211-3c54.4-2.4 91.8-11.2 124.2-23.8 33.6-13 62.2-30.6 90.6-59s46-57 59-90.6c12.6-32.6 21.4-69.8 23.8-124.2 2.4-54.6 3-72 3-211s-0.6-156.4-3-211c-2.4-54.4-11.2-91.8-23.8-124.2-12.6-34-30-62.6-58.6-91-28.4-28.4-57-46-90.6-59-32.6-12.6-69.8-21.4-124.2-23.8-54.8-2.6-72.2-3.2-211.2-3.2v0z"></path>
              <path d="M512 249c-145.2 0-263 117.8-263 263s117.8 263 263 263 263-117.8 263-263c0-145.2-117.8-263-263-263zM512 682.6c-94.2 0-170.6-76.4-170.6-170.6s76.4-170.6 170.6-170.6c94.2 0 170.6 76.4 170.6 170.6s-76.4 170.6-170.6 170.6z"></path>
              <path d="M846.8 238.6c0 33.91-27.49 61.4-61.4 61.4s-61.4-27.49-61.4-61.4c0-33.91 27.49-61.4 61.4-61.4s61.4 27.49 61.4 61.4z"></path>
            </svg>
          </a>
          <span className="profile-rawane-text1">contibutions:</span>
          <div className="profile-rawane-container3">
            <span className="profile-rawane-text2">lessons</span>
            <span className="profile-rawane-text3">1</span>
          </div>
          <div className="profile-rawane-container4">
            <span className="profile-rawane-text4">reviews</span>
            <span className="profile-rawane-text5">0</span>
          </div>
          <span className="profile-rawane-text6">Casablanca, Morocco.</span>
        </div>
      </div>
      <div className="profile-rawane-container5">
        <Footer rootClassName="footer-root-class-name19"></Footer>
      </div>
    </div>
  )
}

export default ProfileRawane
