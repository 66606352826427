import React from 'react'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './profile-yousra-ouad.css'

const ProfileYousraOuad = (props) => {
  return (
    <div className="profile-yousra-ouad-container">
      <Helmet>
        <title>profile-yousra-ouad - dakchi li fhamt</title>
        <meta
          property="og:title"
          content="profile-yousra-ouad - dakchi li fhamt"
        />
      </Helmet>
      <div className="profile-yousra-ouad-container1">
        <div className="profile-yousra-ouad-container2">
          <img
            alt="image"
            src="https://media.licdn.com/dms/image/D4E03AQEGrlBlwk5HQg/profile-displayphoto-shrink_200_200/0/1693489617571?e=2147483647&amp;v=beta&amp;t=YkazfIvkg2uGwCsH_xCHQVs_AqbS99TwHJAZlhXXe1U"
            className="profile-yousra-ouad-image"
          />
          <h1 className="profile-yousra-ouad-text">Yousra Oualiyouddine</h1>
          <span className="profile-yousra-ouad-text1">contibutions:</span>
          <div className="profile-yousra-ouad-container3">
            <span className="profile-yousra-ouad-text2">lessons</span>
            <span className="profile-yousra-ouad-text3">1</span>
          </div>
          <div className="profile-yousra-ouad-container4">
            <span className="profile-yousra-ouad-text4">reviews</span>
            <span className="profile-yousra-ouad-text5">0</span>
          </div>
          <span className="profile-yousra-ouad-text6">
            Mohammédia, Morocco.
          </span>
          <svg
            viewBox="0 0 877.7142857142857 1024"
            className="profile-yousra-ouad-icon"
          >
            <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
          </svg>
        </div>
      </div>
      <div className="profile-yousra-ouad-container5">
        <Footer rootClassName="footer-root-class-name18"></Footer>
      </div>
    </div>
  )
}

export default ProfileYousraOuad
