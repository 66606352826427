import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './codey.css'

const Codey = (props) => {
  return (
    <div id="about" className="codey-container">
      <Helmet>
        <title>codey - dakchi li fhamt</title>
        <meta property="og:title" content="codey - dakchi li fhamt" />
      </Helmet>
      <header data-thq="thq-navbar" className="codey-navbar-interactive">
        <Link to="/" className="codey-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
            className="codey-image"
          />
        </Link>
        <div data-thq="thq-navbar-nav" className="codey-desktop-menu">
          <nav className="codey-links">
            <Link to="/" className="codey-navlink01">
              Home
            </Link>
            <Link to="/donate" className="codey-navlink02">
              Donate
            </Link>
          </nav>
          <div className="codey-buttons">
            <Link to="/apply" className="codey-register button">
              Apply
            </Link>
            <div
              data-thq="thq-dropdown"
              className="codey-thq-dropdown list-item"
            >
              <ul data-thq="thq-dropdown-list" className="codey-dropdown-list">
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown list-item"
                >
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="codey-dropdown-toggle"
                    >
                      <span className="codey-text">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="codey-image1"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown1 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="codey-dropdown-toggle1"
                    >
                      <span className="codey-text01">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="codey-image2"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown2 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown3 list-item"
                ></li>
              </ul>
            </div>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="codey-burger-menu">
          <svg viewBox="0 0 1024 1024" className="codey-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="codey-mobile-menu">
          <div className="codey-nav">
            <div className="codey-top">
              <img
                alt="image"
                src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
                className="codey-logo"
              />
              <div data-thq="thq-close-menu" className="codey-close-menu">
                <svg viewBox="0 0 1024 1024" className="codey-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="codey-links1">
              <Link to="/" className="codey-navlink05">
                Home
              </Link>
              <Link to="/donate" className="codey-navlink06">
                Donate
              </Link>
            </nav>
            <div className="codey-buttons1">
              <Link to="/apply" className="codey-register1 button">
                <span>Apply</span>
                <svg viewBox="0 0 1024 1024" className="codey-icon04">
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="codey-thq-dropdown1 list-item"
            >
              <ul data-thq="thq-dropdown-list" className="codey-dropdown-list1">
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown4 list-item"
                >
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="codey-dropdown-toggle2"
                    >
                      <span className="codey-text03">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="codey-image3"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown5 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="codey-dropdown-toggle3"
                    >
                      <span className="codey-text04">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="codey-image4"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown6 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="codey-dropdown7 list-item"
                ></li>
              </ul>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="codey-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="codey-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="codey-container1">
        <div className="codey-container2">
          <span className="codey-text05">Together we all win</span>
          <span className="codey-text06">
            Codey: Empowering Tomorrow&apos;s Tech Innovators – A Global Hub for
            Youth Coders, Live Workshops, Networking, and International Tech
            Events
          </span>
          <img
            alt="image"
            src="/374204959_689709346395792_7269480407507429149_n-removebg-preview-600h.png"
            className="codey-image5"
          />
          <a
            href="https://discord.gg/22MkaD6ggb"
            target="_blank"
            rel="noreferrer noopener"
            className="codey-link2 navtitle button"
          >
            Discord
          </a>
        </div>
      </div>
      <span className="codey-text07">OUR BOOTCAMP:</span>
      <div className="codey-container3">
        <div className="codey-container4">
          <h1 className="codey-text08">PART 1:</h1>
          <span className="codey-text09">
            (3 weeks) Introduction to Web development (HTML+CSS+JS) and how you
            can make simple static websites. You&apos;ll also create projects
            and get a certificate When you finish Phase 1.
          </span>
        </div>
        <div className="codey-container5">
          <h1 className="codey-text10">PART 2:</h1>
          <span className="codey-text11">
            (7-8weeks) You&apos;ll learn more about HTML+CSS+JS and you&apos;ll
            be able to know how to animate, create beautiful websites and how to
            make your work LIVE! you&apos;ll get a certificate of completion at
            the end of this program as well as a BIG SURPRISE FOR THE TOP
            STUDENTS!
          </span>
        </div>
      </div>
      <div className="codey-container6">
        <span className="codey-text12">APPLY</span>
      </div>
      <div className="codey-container7">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdbnxEx8doN9JX27GzwF1_Md4wR-W7xu9gjAT1iozHDZLRCEQ/viewform"
          className="codey-iframe"
        ></iframe>
      </div>
      <Link to="/profile-yousra-ouad" className="codey-navlink09">
        <div className="codey-container8">
          <h1 className="codey-text13">By: Yousra Ouad</h1>
          <img
            alt="image"
            src="https://media.licdn.com/dms/image/D4E03AQEGrlBlwk5HQg/profile-displayphoto-shrink_200_200/0/1693489617571?e=2147483647&amp;v=beta&amp;t=YkazfIvkg2uGwCsH_xCHQVs_AqbS99TwHJAZlhXXe1U"
            className="codey-image6"
          />
        </div>
      </Link>
      <Footer rootClassName="footer-root-class-name20"></Footer>
    </div>
  )
}

export default Codey
