import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './unit2-modals-salah-mamouni.css'

const Unit2ModalsSalahMamouni = (props) => {
  return (
    <div className="unit2-modals-salah-mamouni-container">
      <Helmet>
        <title>unit2-modals-salah-mamouni - dakchi li fhamt</title>
        <meta
          property="og:title"
          content="unit2-modals-salah-mamouni - dakchi li fhamt"
        />
      </Helmet>
      <div className="unit2-modals-salah-mamouni-container01">
        <Link
          to="/english-course1"
          className="unit2-modals-salah-mamouni-navlink"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="unit2-modals-salah-mamouni-icon"
          >
            <path d="M498 166l-346 346 346 346-76 76-422-422 422-422z"></path>
          </svg>
        </Link>
        <h1 className="unit2-modals-salah-mamouni-text">
          <span className="unit2-modals-salah-mamouni-text1">Modals</span>
          <br></br>
        </h1>
      </div>
      <div className="unit2-modals-salah-mamouni-container02">
        <iframe
          src="https://www.youtube.com/embed/IVDUFvmfKIM?si=ojlID5jUAB1frysT"
          allowFullScreen
          className="unit2-modals-salah-mamouni-iframe"
        ></iframe>
      </div>
      <div className="unit2-modals-salah-mamouni-container03">
        <div className="unit2-modals-salah-mamouni-container04">
          <h1 className="unit2-modals-salah-mamouni-text3">RESUME</h1>
          <a
            href="MODALS.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className="unit2-modals-salah-mamouni-link navtitle button"
          >
            Download
          </a>
        </div>
      </div>
      <div className="unit2-modals-salah-mamouni-container05">
        <h1 className="unit2-modals-salah-mamouni-text4">QUIZ</h1>
      </div>
      <div className="unit2-modals-salah-mamouni-container06">
        <div className="unit2-modals-salah-mamouni-container07">
          <div className="unit2-modals-salah-mamouni-container08">
          <iframe className='applyt' src="https://tripetto.app/run/T3NCV5C9JK" width >   </iframe>
          </div>
        </div>
      </div>
      <div className="unit2-modals-salah-mamouni-container09">
        <Link
          to="/profile-salah-mamouni"
          className="unit2-modals-salah-mamouni-navlink1"
        >
          <div className="unit2-modals-salah-mamouni-container10">
            <h1 className="unit2-modals-salah-mamouni-text5">
              By: Salah Edine Mamouni
            </h1>
            <img
              alt="image"
              src="https://lh3.googleusercontent.com/a/ACg8ocLPd_UccA6ftMcIvfzXcrYDrntYnXJsBsYuDNVWfRPoS3Y=s288-c-no"
              className="unit2-modals-salah-mamouni-image"
            />
          </div>
        </Link>
      </div>
      <Footer rootClassName="footer-root-class-name12"></Footer>
    </div>
  )
}

export default Unit2ModalsSalahMamouni
