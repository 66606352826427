import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './english-courses.css'

const EnglishCourses = (props) => {
  return (
    <div className="english-courses-container">
      <Helmet>
        <title>english-courses - dakchi li fhamt</title>
        <meta property="og:title" content="english-courses - dakchi li fhamt" />
      </Helmet>
      <header
        data-thq="thq-navbar"
        className="english-courses-navbar-interactive"
      >
        <Link to="/" className="english-courses-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-1500h.png"
            className="english-courses-image"
          />
        </Link>
        <div data-thq="thq-navbar-nav" className="english-courses-desktop-menu">
          <nav className="english-courses-links">
            <Link to="/" className="english-courses-navlink1">
              Home
            </Link>
            <Link to="/donate" className="english-courses-navlink2">
              Donate
            </Link>
          </nav>
          <div className="english-courses-buttons">
            <Link to="/apply" className="english-courses-register button">
              Apply
            </Link>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="english-courses-burger-menu">
          <svg viewBox="0 0 1024 1024" className="english-courses-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="english-courses-mobile-menu">
          <div className="english-courses-nav">
            <div className="english-courses-top">
              <Link to="/" className="english-courses-navlink3">
                <img
                  alt="image"
                  src="/untitled_designfgfhdfdgh-removebg-preview-1500h.png"
                  className="english-courses-logo"
                />
              </Link>
              <div
                data-thq="thq-close-menu"
                className="english-courses-close-menu"
              >
                <svg viewBox="0 0 1024 1024" className="english-courses-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="english-courses-links1">
              <Link to="/" className="english-courses-navlink4">
                Home
              </Link>
              <Link to="/donate" className="english-courses-navlink5">
                Donate
              </Link>
            </nav>
            <div className="english-courses-buttons1">
              <Link to="/apply" className="english-courses-register1 button">
                <span>Apply</span>
                <svg viewBox="0 0 1024 1024" className="english-courses-icon04">
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="english-courses-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="english-courses-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="english-courses-container01">
        <span className="english-courses-text01">ENGLISH COURSES:</span>
        <img
          alt="image"
          src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
          className="english-courses-image1"
        />
      </div>
      <div className="english-courses-container02">
        <div className="english-courses-container03">
          <span className="english-courses-text02">
            <span className="english-courses-text03">
              This is a project for:
            </span>
            <br></br>
          </span>
          <img
            alt="image"
            src="https://images.ctfassets.net/0t98t0c4kteu/4eylnFldrXWnQJcNG6M6Xm/a5a1e80f720a0a017a5954dafe240828/Rise_PrimaryAlt-01.svg?w=320"
            className="english-courses-image2"
          />
        </div>
      </div>
      <div className="english-courses-container04">
        <img
          alt="image"
          src="https://i.ibb.co/nrrFh5g/Photo-Room-20231207-192027-2.png"
          className="english-courses-image3"
        />
        <div className="english-courses-container05">
          <div className="english-courses-container06">
            <div className="english-courses-container07">
              <span className="english-courses-text05">
                2BAC ENGLISH: UNIT 1
              </span>
              <span className="english-courses-text06">Lessons:</span>
              <span className="english-courses-text07">
                •Functions : Expressing opinion
              </span>
              <span className="english-courses-text08">
                •Grammar : Gerund and Infinitive
              </span>
            </div>
          </div>
          <Link
            to="/english-course1"
            className="english-courses-navlink6 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <div className="english-courses-container08">
        <img
          alt="image"
          src="https://i.ibb.co/nrrFh5g/Photo-Room-20231207-192027-2.png"
          className="english-courses-image4"
        />
        <div className="english-courses-container09">
          <div className="english-courses-container10">
            <div className="english-courses-container11">
              <span className="english-courses-text09">
                2BAC ENGLISH: UNIT 2
              </span>
              <span className="english-courses-text10">Lessons:</span>
              <span className="english-courses-text11">
                •Functions : Expressing lack of understanding and asking for
                clarification
              </span>
              <span className="english-courses-text12">
                •Grammar 2 : Modals
              </span>
            </div>
          </div>
          <Link
            to="/english-course11"
            className="english-courses-navlink7 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <div className="english-courses-container12">
        <img
          alt="image"
          src="https://i.ibb.co/nrrFh5g/Photo-Room-20231207-192027-2.png"
          className="english-courses-image5"
        />
        <div className="english-courses-container13">
          <div className="english-courses-container14">
            <div className="english-courses-container15">
              <span className="english-courses-text13">
                2BAC ENGLISH: UNIT 3
              </span>
              <span className="english-courses-text14">Lessons:</span>
              <span className="english-courses-text15">
                •Functions : Expressing purpose
              </span>
              <span className="english-courses-text16">
                •Grammar : Past Perfect
              </span>
            </div>
          </div>
          <Link
            to="/english-course111"
            className="english-courses-navlink8 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <footer className="english-courses-footer">
        <img
          alt="logo"
          src="/untitled_designfgfhdfdgh-removebg-preview-1500h.png"
          className="english-courses-image6"
        />
        <span className="english-courses-text17">
          © 2023 DAKCHI LI FHAMT, All Rights Reserved.
        </span>
        <div className="english-courses-icon-group1">
          <svg
            viewBox="0 0 950.8571428571428 1024"
            className="english-courses-icon10"
          >
            <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
          </svg>
          <svg
            viewBox="0 0 877.7142857142857 1024"
            className="english-courses-icon12"
          >
            <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
          </svg>
          <svg
            viewBox="0 0 602.2582857142856 1024"
            className="english-courses-icon14"
          >
            <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
          </svg>
        </div>
      </footer>
    </div>
  )
}

export default EnglishCourses
