import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './gerund-and-infinitive1.css'

const GerundAndInfinitive1 = (props) => {
  return (
    <div className="gerund-and-infinitive1-container">
      <Helmet>
        <title>gerund-and-infinitive1 - dakchi li fhamt</title>
        <meta
          property="og:title"
          content="gerund-and-infinitive1 - dakchi li fhamt"
        />
      </Helmet>
      <div className="gerund-and-infinitive1-container01">
        <Link to="/english-course1" className="gerund-and-infinitive1-navlink">
          <svg viewBox="0 0 1024 1024" className="gerund-and-infinitive1-icon">
            <path d="M498 166l-346 346 346 346-76 76-422-422 422-422z"></path>
          </svg>
        </Link>
        <h1 className="gerund-and-infinitive1-text">Gerund And Infinitive</h1>
      </div>
      <div className="gerund-and-infinitive1-container02">
        <iframe
          src="https://www.youtube.com/embed/yDMZJ7LgrGY?si=bt6DQ0oDzrwSvACK"
          allowFullScreen
          className="gerund-and-infinitive1-iframe"
        ></iframe>
      </div>
      <div className="gerund-and-infinitive1-container03">
        <div className="gerund-and-infinitive1-container04">
          <h1 className="gerund-and-infinitive1-text1">RESUME</h1>
          <button
            type="button"
            className="gerund-and-infinitive1-button navtitle button"
          >
            Download
          </button>
        </div>
      </div>
      <div className="gerund-and-infinitive1-container05">
        <h1 className="gerund-and-infinitive1-text2">QUIZ</h1>
      </div>
      <div className="gerund-and-infinitive1-container06">
        <div className="gerund-and-infinitive1-container07">
          <div className="gerund-and-infinitive1-container08">
            <Script
              html={`<div id="tripetto-knn2dt"></div>
<script src="https://cdn.jsdelivr.net/npm/@tripetto/runner"></script>
<script src="https://cdn.jsdelivr.net/npm/@tripetto/runner-autoscroll"></script>
<script src="https://cdn.jsdelivr.net/npm/@tripetto/studio"></script>
<script>
TripettoStudio.form({
    runner: TripettoAutoscroll,
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiU1kxSlpDSzQ2eFpWa29VNUtiRVFLWFI1eUJuR1NFVktIZ1E0WVpwVDhqST0iLCJkZWZpbml0aW9uIjoiL3lzeFhRaVp5NXE5VHZQNGdIRjUramtOQzIwcTIzSVpWMWQ0NC9Ici9Pbz0iLCJ0eXBlIjoiY29sbGVjdCJ9.iQLCY3SV8x5yWI97K1YX0Sk-bFkQVQ2Jz-_vXTZ-R34",
    element: "tripetto-knn2dt"
});
</script>`}
            ></Script>
          </div>
        </div>
      </div>
      <div className="gerund-and-infinitive1-container09">
        <Link
          to="/profile-salah-mamouni"
          className="gerund-and-infinitive1-navlink1"
        >
          <div className="gerund-and-infinitive1-container10">
            <h1 className="gerund-and-infinitive1-text3">
              By: Salah Edine Mamouni
            </h1>
            <img
              alt="image"
              src="https://lh3.googleusercontent.com/a/ACg8ocLPd_UccA6ftMcIvfzXcrYDrntYnXJsBsYuDNVWfRPoS3Y=s288-c-no"
              className="gerund-and-infinitive1-image"
            />
          </div>
        </Link>
      </div>
      <Footer rootClassName="footer-root-class-name9"></Footer>
    </div>
  )
}

export default GerundAndInfinitive1
