import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import './style.css';
import Home from './views/home';
import ProfileYousraOuad from './views/profile-yousra-ouad';
import BacEnglishUnit1 from './views/bac-english-unit-1';
import BacEnglishUnit3 from './views/bac-english-unit-3';
import Unit2ModalsSalahMamouni from './views/unit2-modals-salah-mamouni';
import ProfileSalahMamouni from './views/profile-salah-mamouni';
import Donate from './views/donate';
import Mental from './views/mental';
import Unit3PastperfectSalahMamouni from './views/unit3-pastperfect-salah-mamouni';
import Homear from './views/homear';
import GerundAndInfinitive1 from './views/gerund-and-infinitive1';
import Unit1GerundAndInfinitiveSalahMamouni from './views/unit1-gerund-and-infinitive-salah-mamouni';
import Apply from './views/apply';
import BacEnglishUnit2 from './views/bac-english-unit-2';
import EnglishCourses from './views/english-courses';
import Unit3ExpressingPurposeSalahMamouni from './views/unit3-expressing-purpose-salah-mamouni';
import Codey from './views/codey';
import Unit2ExpressingLackOfUnderstandingSalahMamouni from './views/unit2-expressing-lack-of-understanding-salah-mamouni';
import ProfileRawane from './views/profile-rawane';
import Unit1ExpressingOpinionSalahMamouni from './views/unit1-expressing-opinion-salah-mamouni';
import NotFound from './views/not-found';

ReactGA.initialize('G-QTT8THSPXS');

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={ProfileYousraOuad} exact path="/profile-yousra-ouad" />
        <Route component={BacEnglishUnit1} exact path="/english-course1" />
        <Route component={BacEnglishUnit3} exact path="/english-course111" />
        <Route component={Unit2ModalsSalahMamouni} exact path="/unit2-modals-salah-mamouni" />
        <Route component={ProfileSalahMamouni} exact path="/profile-salah-mamouni" />
        <Route component={Donate} exact path="/donate" />
        <Route component={Mental} exact path="/mental" />
        <Route component={Unit3PastperfectSalahMamouni} exact path="/unit3-pastperfect-salah-mamouni" />
        <Route component={Homear} exact path="/homear" />
        <Route component={GerundAndInfinitive1} exact path="/gerund-and-infinitive1" />
        <Route component={Unit1GerundAndInfinitiveSalahMamouni} exact path="/unit1-gerund-and-infinitive-salah-mamouni" />
        <Route component={Apply} exact path="/apply" />
        <Route component={BacEnglishUnit2} exact path="/english-course11" />
        <Route component={EnglishCourses} exact path="/english-course" />
        <Route component={Unit3ExpressingPurposeSalahMamouni} exact path="/unit3-expressing-purpose-salah-mamouni" />
        <Route component={Codey} exact path="/codey" />
        <Route component={Unit2ExpressingLackOfUnderstandingSalahMamouni} exact path="/unit2-expressing-lack-of-understanding-salah-mamouni" />
        <Route component={ProfileRawane} exact path="/profile-rawane" />
        <Route component={Unit1ExpressingOpinionSalahMamouni} exact path="/unit1-expressing-opinion-salah-mamouni" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));

