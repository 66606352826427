import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './unit3-expressing-purpose-salah-mamouni.css'

const Unit3ExpressingPurposeSalahMamouni = (props) => {
  return (
    <div className="unit3-expressing-purpose-salah-mamouni-container">
      <Helmet>
        <title>unit3-Expressing-purpose-salah-mamouni - dakchi li fhamt</title>
        <meta
          property="og:title"
          content="unit3-Expressing-purpose-salah-mamouni - dakchi li fhamt"
        />
      </Helmet>
      <div className="unit3-expressing-purpose-salah-mamouni-container01">
        <Link
          to="/english-course111"
          className="unit3-expressing-purpose-salah-mamouni-navlink"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="unit3-expressing-purpose-salah-mamouni-icon"
          >
            <path d="M498 166l-346 346 346 346-76 76-422-422 422-422z"></path>
          </svg>
        </Link>
        <h1 className="unit3-expressing-purpose-salah-mamouni-text">
          <span className="unit3-expressing-purpose-salah-mamouni-text1">
            Expressing purpose
          </span>
          <br></br>
        </h1>
      </div>
      <div className="unit3-expressing-purpose-salah-mamouni-container02">
        <iframe
          src="https://www.youtube.com/embed/pboCtUML9zw?si=UO27Q4asbenCSXSG"
          allowFullScreen
          className="unit3-expressing-purpose-salah-mamouni-iframe"
        ></iframe>
      </div>
      <div className="unit3-expressing-purpose-salah-mamouni-container03">
        <div className="unit3-expressing-purpose-salah-mamouni-container04">
          <h1 className="unit3-expressing-purpose-salah-mamouni-text3">
            RESUME
          </h1>
          <a
            href="expressingpurpose.pdf"
            target="_blank"
            rel="noreferrer noopener"
            className="unit3-expressing-purpose-salah-mamouni-link navtitle button"
          >
            Download
          </a>
        </div>
      </div>
      <div className="unit3-expressing-purpose-salah-mamouni-container05">
        <h1 className="unit3-expressing-purpose-salah-mamouni-text4">QUIZ</h1>
      </div>
      <div className="unit3-expressing-purpose-salah-mamouni-container06">
        <div className="unit3-expressing-purpose-salah-mamouni-container07">
          <div className="unit3-expressing-purpose-salah-mamouni-container08">
          <iframe className='applyt' src="https://tripetto.app/run/IKL7G6S4KI" width >   </iframe>
          </div>
        </div>
      </div>
      <div className="unit3-expressing-purpose-salah-mamouni-container09">
        <Link
          to="/profile-salah-mamouni"
          className="unit3-expressing-purpose-salah-mamouni-navlink1"
        >
          <div className="unit3-expressing-purpose-salah-mamouni-container10">
            <h1 className="unit3-expressing-purpose-salah-mamouni-text5">
              By: Salah Edine Mamouni
            </h1>
            <img
              alt="image"
              src="https://lh3.googleusercontent.com/a/ACg8ocLPd_UccA6ftMcIvfzXcrYDrntYnXJsBsYuDNVWfRPoS3Y=s288-c-no"
              className="unit3-expressing-purpose-salah-mamouni-image"
            />
          </div>
        </Link>
      </div>
      <Footer rootClassName="footer-root-class-name14"></Footer>
    </div>
  )
}

export default Unit3ExpressingPurposeSalahMamouni
