import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './homear.css'

const Homear = (props) => {
  return (
    <div id="about" className="homear-container">
      <Helmet>
        <title>Homear - dakchi li fhamt</title>
        <meta property="og:title" content="Homear - dakchi li fhamt" />
      </Helmet>
      <header data-thq="thq-navbar" className="homear-navbar-interactive">
        <Link to="/homear" className="homear-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
            className="homear-image"
          />
        </Link>
        <div data-thq="thq-navbar-nav" className="homear-desktop-menu">
          <nav className="homear-links">
            <span className="homear-text">About</span>
            <span className="homear-text01">courses</span>
            <Link to="/donate" className="homear-navlink01">
              Donate
            </Link>
          </nav>
          <div className="homear-buttons">
            <div
              data-thq="thq-dropdown"
              className="homear-thq-dropdown list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="homear-dropdown-toggle"
              >
                <span className="homear-text02">Language</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="homear-dropdown-arrow"
                >
                  <svg viewBox="0 0 1024 1024" className="homear-icon">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul data-thq="thq-dropdown-list" className="homear-dropdown-list">
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown list-item"
                >
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="homear-dropdown-toggle1"
                    >
                      <span className="homear-text03">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="homear-image1"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown1 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="homear-dropdown-toggle2"
                    >
                      <span className="homear-text04">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="homear-image2"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown2 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown3 list-item"
                ></li>
              </ul>
            </div>
            <Link to="/apply" className="homear-register button">
              Apply
            </Link>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="homear-burger-menu">
          <svg viewBox="0 0 1024 1024" className="homear-icon02">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="homear-mobile-menu">
          <div className="homear-nav">
            <div className="homear-top">
              <img
                alt="image"
                src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
                className="homear-logo"
              />
              <div data-thq="thq-close-menu" className="homear-close-menu">
                <svg viewBox="0 0 1024 1024" className="homear-icon04">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="homear-links1">
              <span className="homear-text05">About</span>
              <span className="homear-text06">Courses</span>
              <Link to="/donate" className="homear-navlink04">
                Donate
              </Link>
            </nav>
            <div className="homear-buttons1">
              <Link to="/apply" className="homear-register1 button">
                <span>Apply</span>
                <svg viewBox="0 0 1024 1024" className="homear-icon06">
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
            <div
              data-thq="thq-dropdown"
              className="homear-thq-dropdown1 list-item"
            >
              <div
                data-thq="thq-dropdown-toggle"
                className="homear-dropdown-toggle3"
              >
                <span className="homear-text08">Language</span>
                <div
                  data-thq="thq-dropdown-arrow"
                  className="homear-dropdown-arrow1"
                >
                  <svg viewBox="0 0 1024 1024" className="homear-icon08">
                    <path d="M426 726v-428l214 214z"></path>
                  </svg>
                </div>
              </div>
              <ul
                data-thq="thq-dropdown-list"
                className="homear-dropdown-list1"
              >
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown4 list-item"
                >
                  <Link to="/homear">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="homear-dropdown-toggle4"
                    >
                      <span className="homear-text09">Darija</span>
                      <img
                        alt="image"
                        src="https://cdn.countryflags.com/thumbs/morocco/flag-400.png"
                        className="homear-image3"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown5 list-item"
                >
                  <Link to="/">
                    <div
                      data-thq="thq-dropdown-toggle"
                      className="homear-dropdown-toggle5"
                    >
                      <span className="homear-text10">English</span>
                      <img
                        alt="image"
                        src="https://www.countryflags.com/wp-content/uploads/united-kingdom-flag-png-large.png"
                        className="homear-image4"
                      />
                    </div>
                  </Link>
                </li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown6 list-item"
                ></li>
                <li
                  data-thq="thq-dropdown"
                  className="homear-dropdown7 list-item"
                ></li>
              </ul>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="homear-icon10"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="homear-icon12"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="homear-container01">
        <div className="homear-container02">
          <span className="homear-text11">مع بعضياتنا كلشي رابح</span>
          <img
            alt="image"
            src="/hero-banner-500h.png"
            className="homear-image5"
          />
          <span className="homear-text12">
            دكشي لي فهمت هي منصة فين كقدرو تلاميذ إشاركو لمعرفة ديالهم مع
            بعضياتهوم
          </span>
          <button type="button" className="homear-button button">
            <span className="homear-text13">الدروس</span>
            <svg viewBox="0 0 1024 1024" className="homear-icon14">
              <path d="M512 909.312l-303.829-303.829c-49.92-49.92-49.92-131.115 0-180.992 46.677-46.677 127.061-48.555 175.829-4.907v-206.251c0-70.571 57.429-128 128-128s128 57.429 128 128v206.251c48.768-43.648 129.152-41.771 175.829 4.907 49.92 49.877 49.92 131.072 0 180.992l-303.829 303.829zM298.667 472.32c-11.435 0-22.101 4.437-30.165 12.501-16.683 16.64-16.683 43.648 0 60.331l243.499 243.499 243.499-243.499c16.683-16.683 16.683-43.691 0-60.331-16.171-16.171-44.16-16.171-60.331 0l-140.501 140.501v-411.989c0-23.509-19.115-42.667-42.667-42.667s-42.667 19.157-42.667 42.667v411.989l-140.501-140.501c-8.064-8.064-18.731-12.501-30.165-12.501z"></path>
            </svg>
          </button>
          <img
            alt="image"
            src="/10717948_2091-300h.jpg"
            className="homear-image6"
          />
        </div>
      </div>
      <div className="homear-container03">
        <div className="homear-container04">
          <span className="homear-text14">
            هدا بروجي على قبل
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <img
            alt="image"
            src="https://images.ctfassets.net/0t98t0c4kteu/4eylnFldrXWnQJcNG6M6Xm/a5a1e80f720a0a017a5954dafe240828/Rise_PrimaryAlt-01.svg?w=320"
            className="homear-image7"
          />
        </div>
      </div>
      <span id="about us" className="homear-text15">
        OUR MISSION:
      </span>
      <div className="homear-container05">
        <div className="homear-container06">
          <img
            alt="image"
            src="/brainstorm-meeting%20(1)-500h.jpg"
            className="homear-image8"
          />
          <span className="homear-text16">
            <span>
              &quot;في داكشي لي فهمت، نهدف إلى خلق مساحة تعلم شاملة. نقدم منصة
              بدون تكلفة للطلاب لتبادل الافكار، وتعزيز التعلم التعاوني. هدفنا هو
              بناء مجتمع حيث يمكن لكل متعلم المساهمة والتعلم والازدهار بشكل
              جماعي. نلتزم بدعم النمو الفكري، وتعزيز وجهات نظر متنوعة، وترسيخ
              ثقافة التعلم المشترك للجميع.&quot;
            </span>
            <br></br>
          </span>
        </div>
        <span className="homear-text19">صلاح الدين ماموني-</span>
      </div>
      <div id="courses" className="homear-container07">
        <span className="homear-text20">تفرج في</span>
        <span className="homear-text21">أشهر الدروس</span>
      </div>
      <div className="homear-container08">
        <div className="homear-container09">
          <Link to="/profile-salah-mamouni" className="homear-navlink07">
            Salah Edine Mamouni
          </Link>
        </div>
        <div className="homear-container10">
          <span className="homear-text22">2BAC ENGLISH</span>
          <ul className="homear-ul list">
            <li className="list-item">
              <span className="homear-text23">•Youth</span>
            </li>
            <li className="list-item">
              <span className="homear-text24">•Humour</span>
            </li>
            <li className="list-item">
              <span className="homear-text25">•Education</span>
            </li>
          </ul>
          <Link
            to="/english-course"
            className="homear-navlink08 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <div className="homear-container11">
        <div className="homear-container12">
          <Link to="/profile-yousra-ouad" className="homear-navlink09">
            Yousra Oual
          </Link>
        </div>
        <div className="homear-container13">
          <span className="homear-text26">WEB DEVELOPEMENT</span>
          <ul className="homear-ul1 list">
            <li className="list-item">
              <span className="homear-text27">•Html</span>
            </li>
            <li className="list-item">
              <span className="homear-text28">•CSS</span>
            </li>
            <li className="list-item">
              <span className="homear-text29">•JavaScript</span>
            </li>
          </ul>
          <Link to="/codey" className="homear-navlink10 navtitle button">
            START
          </Link>
        </div>
      </div>
      <div className="homear-container14">
        <div className="homear-container15">
          <Link to="/profile-rawane" className="homear-navlink11">
            Rawane Loutfi
          </Link>
        </div>
        <div className="homear-container16">
          <span className="homear-text30">
            Understanding the layers of ADHD
          </span>
          <Link
            to="/mental"
            className="homear-navlink12 navtitle button"
          >
            START
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  )
}

export default Homear