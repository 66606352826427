import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './mental.css'

const Mental = (props) => {
  return (
    <div className="mental-container">
      <Helmet>
        <title>mental - dakchi li fhamt</title>
        <meta property="og:title" content="mental - dakchi li fhamt" />
      </Helmet>
      <div className="mental-container1">
        <Link to="/" className="mental-navlink">
          <svg viewBox="0 0 1024 1024" className="mental-icon">
            <path d="M498 166l-346 346 346 346-76 76-422-422 422-422z"></path>
          </svg>
        </Link>
        <h1 className="mental-text">ADHD</h1>
      </div>
      <div className="mental-container2">
        <iframe
          src="https://www.youtube.com/embed/8M_dE8l8J44"
          allowFullScreen
          className="mental-iframe"
        ></iframe>
      </div>
      <div className="mental-container3">
        <Link to="/profile-rawane" className="mental-navlink1">
          <div className="mental-container4">
            <h1 className="mental-text1">By: Rawane</h1>
            <img
              alt="image"
              src="/4e42442f443359cf0115c075a788f546-200w.jpg"
              className="mental-image"
            />
          </div>
        </Link>
      </div>
      <Footer rootClassName="footer-root-class-name21"></Footer>
    </div>
  )
}

export default Mental
