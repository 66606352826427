import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import './apply.css'

const Apply = (props) => {
  return (
    <div id="about" className="apply-container">
      <Helmet>
        <title>apply - dakchi li fhamt</title>
        <meta property="og:title" content="apply - dakchi li fhamt" />
      </Helmet>
      <header data-thq="thq-navbar" className="apply-navbar-interactive">
        <Link to="/" className="apply-navlink">
          <img
            alt="logo"
            src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
            className="apply-image"
          />
        </Link>
        <div data-thq="thq-navbar-nav" className="apply-desktop-menu">
          <nav className="apply-links">
            <Link to="/" className="apply-navlink1">
              Home
            </Link>
            <Link to="/donate" className="apply-navlink2">
              Donate
            </Link>
          </nav>
          <div className="apply-buttons">
            <Link to="/apply" className="apply-register button">
              Apply
            </Link>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="apply-burger-menu">
          <svg viewBox="0 0 1024 1024" className="apply-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="apply-mobile-menu">
          <div className="apply-nav">
            <div className="apply-top">
              <Link to="/" className="apply-navlink3">
                <img
                  alt="image"
                  src="/untitled_designfgfhdfdgh-removebg-preview-200h.png"
                  className="apply-logo"
                />
              </Link>
              <div data-thq="thq-close-menu" className="apply-close-menu">
                <svg viewBox="0 0 1024 1024" className="apply-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="apply-links1">
              <Link to="/" className="apply-navlink4">
                Home
              </Link>
              <Link to="/donate" className="apply-navlink5">
                Donate
              </Link>
            </nav>
            <div className="apply-buttons1">
              <Link to="/apply" className="apply-register1 button">
                <span>Apply</span>
                <svg viewBox="0 0 1024 1024" className="apply-icon04">
                  <path d="M512 896c-34.176 0-66.347-13.312-90.496-37.504s-37.547-56.363-37.504-90.581c0-31.829 11.563-42.581 32.597-85.248h-206.336c-70.571 0-128-57.472-128-128 0-70.571 57.429-128 128-128h206.336c-21.077-42.667-32.597-53.547-32.597-85.376 0.043-34.219 13.312-66.304 37.547-90.496 48.299-48.299 132.608-48.341 180.949 0.043l303.829 303.829-303.829 303.829c-24.149 24.192-56.32 37.504-90.496 37.504zM210.261 512c-23.552 0-42.667 19.157-42.667 42.667s19.115 42.667 42.667 42.667h412.075l-140.501 140.501c-8.064 8.064-12.501 18.731-12.501 30.123 0 11.477 4.437 22.144 12.501 30.208 16.171 16.128 44.16 16.128 60.331 0l243.499-243.499-243.499-243.499c-16.171-16.128-44.16-16.128-60.331 0-8.064 8.064-12.501 18.731-12.501 30.123 0 11.435 4.437 22.144 12.501 30.208l140.501 140.501h-412.075z"></path>
                </svg>
              </Link>
            </div>
          </div>
          <div>
            <a
              href="http://instagram.com/dakchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 877.7142857142857 1024"
                className="apply-icon06"
              >
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
            </a>
            <a
              href="http://facebook.com/dkchi.li.fhamt/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <svg
                viewBox="0 0 602.2582857142856 1024"
                className="apply-icon08"
              >
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </a>
          </div>
        </div>
      </header>
      <div className="apply-container1">
        <div className="apply-container2">
          <span className="apply-text1">Together we all win</span>
          <img
            alt="image"
            src="/10717948_2091-300h.jpg"
            className="apply-image1"
          />
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1558655146-605d86ed31b3?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhcHBsaWNhdGlvbnxlbnwwfHx8fDE3MDQzNzcwMDF8MA&amp;ixlib=rb-4.0.3&amp;w=500"
            className="apply-image2"
          />
          <span className="apply-text2">
            Become a student teacher on dakchi li fhamt
          </span>
          <a href="#apply" className="apply-link2 button">
            <span className="apply-text3">Apply</span>
            <svg viewBox="0 0 1024 1024" className="apply-icon10">
              <path d="M512 909.312l-303.829-303.829c-49.92-49.92-49.92-131.115 0-180.992 46.677-46.677 127.061-48.555 175.829-4.907v-206.251c0-70.571 57.429-128 128-128s128 57.429 128 128v206.251c48.768-43.648 129.152-41.771 175.829 4.907 49.92 49.877 49.92 131.072 0 180.992l-303.829 303.829zM298.667 472.32c-11.435 0-22.101 4.437-30.165 12.501-16.683 16.64-16.683 43.648 0 60.331l243.499 243.499 243.499-243.499c16.683-16.683 16.683-43.691 0-60.331-16.171-16.171-44.16-16.171-60.331 0l-140.501 140.501v-411.989c0-23.509-19.115-42.667-42.667-42.667s-42.667 19.157-42.667 42.667v411.989l-140.501-140.501c-8.064-8.064-18.731-12.501-30.165-12.501z"></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="apply-container3">
        <div className="apply-container4">
          <span className="apply-text4">
            <span className="apply-text5">This is a project for:</span>
            <br></br>
          </span>
          <img
            alt="image"
            src="https://images.ctfassets.net/0t98t0c4kteu/4eylnFldrXWnQJcNG6M6Xm/a5a1e80f720a0a017a5954dafe240828/Rise_PrimaryAlt-01.svg?w=320"
            className="apply-image3"
          />
        </div>
      </div>
      <span id="apply" className="apply-text7">
        Apply
      </span>
      <div className="apply-container5">
        <div className="apply-container6">
         <iframe className='applyt' src="https://tripetto.app/run/FBNXRUQ1PQ" width >   </iframe>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name8"></Footer>
    </div>
  )
}

export default Apply
